import UnderConstructionImage from "../assets/images/underConstruction.svg";
import Logo from "../assets/Logo.svg";

export const UnderConstruction = () => {
  return (
    <div className="flex flex-col justify-between md:flex-row-reverse md:justify-center md:gap-5 1150:gap-10 pt-12 md:pt-14">
      <div className="text-center mb-6 mx-auto md:mx-0 md:mt-16 1150:mt-10">
        <p className="text-[20px] md:text-[24px] 1150:text-4xl text-color02">
          WEBSITE IS UNDER
        </p>
        <p className="text-[32px] md:text-[38px] 1150:text-[64px] font-bold text-color03 mb-2">
          CONSTRUCTION
        </p>
        <div className="w-[264px] md:w-[317px] 1150:w-[580px] mx-auto">
          <img src={UnderConstructionImage} alt="Page Under Construction" />
        </div>
      </div>
      <div className="bg-color01 w-[300px] h-[330px] md:w-[360px] md:h-[396px] 1150:w-[450px] 1150:h-[495px] rounded-xl mx-auto md:mx-0">
        <div className="w-[132px] md:w-[158px] 1150:w-[200px] border-4 border-white rounded-full mx-auto mt-10">
          <img src={Logo} alt="Logo" className="p-6" />
        </div>
        <div className="flex justify-center mt-10 text-color04">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="size-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
            />
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1 1 15 0Z"
            />
          </svg>
          <span className="1150:text-lg">58a Avenue Road Mosman 2088</span>
        </div>
        <div className="flex justify-center text-color04">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="size-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M10.5 1.5H8.25A2.25 2.25 0 0 0 6 3.75v16.5a2.25 2.25 0 0 0 2.25 2.25h7.5A2.25 2.25 0 0 0 18 20.25V3.75a2.25 2.25 0 0 0-2.25-2.25H13.5m-3 0V3h3V1.5m-3 0h3m-3 18.75h3"
            />
          </svg>

          <span className="1150:text-lg">0451837575</span>
        </div>
        <p className="text-center mt-8 md:mt-14 1150:mt-20 font-normal text-color02 md:text-lg 1150:text-xl">
          THANKS FOR YOUR PATIENCE.
        </p>
      </div>
    </div>
  );
};
